<template>
  <div class="accordion-details">
    <div class="d-flex flex-column mb-3">
      <span class="text-dark-darken font-weight-bold">{{
        $t(`orderEditModals.infoDetails.driver`) | capitalize
      }}</span>
      <span class="text-dark-darken">{{ data.driver.name || 'N/A' }}</span>
    </div>
    <div class="d-flex flex-column mb-3">
      <div v-if="data.operation.type === 'spot'" class="mb-3 d-flex flex-column">
        <span class="text-dark-darken font-weight-bold">{{
          $t(`orderEditModals.infoDetails.pickupAddress`) | capitalize
        }}</span>
        <span class="text-dark-darken">{{ getSpotAddress || 'N/A' }}</span>
      </div>
      <span class="text-dark-darken font-weight-bold">{{
        (data.operation.type === 'pickup'
          ? $t(`orderEditModals.infoDetails.pickupAddress`)
          : $t(`orderEditModals.infoDetails.deliveryAddress`)) | capitalize
      }}</span>
      <span class="text-dark-darken">{{ getAddress || 'N/A' }}</span>
    </div>
    <div class="d-flex flex-column mb-3">
      <div class="d-flex flex-column" v-if="data.operation.type !== 'spot'">
        <span class="text-dark-darken font-weight-bold mb-2 mt-3">{{
          $t(`orderEditModals.infoDetails.${getOperationType}Local`) | capitalize
        }}</span>
        <static-background-image
          :data="data"
          delivery
          pickedup
          :textKey="getOperationType === 'delivery' ? 'DeliveryLocal' : 'PickupLocal'"
        />
      </div>
      <div v-else class="d-flex flex-column">
        <span class="text-dark-darken font-weight-bold mb-2">{{
          $tc(`orderEditModals.infoDetails.signature`, 2) | capitalize
        }}</span>
        <carousel
          id="carousel-signatures"
          autoplay
          loop
          :perPage="1"
          :adjustableHeight="true"
          :autoplayHoverPause="false"
          :centerMode="true"
          :mouseDrag="false"
          :paginationEnabled="false"
          :autoplayTimeout="3000"
        >
          <slide data-index="0">
            <static-background-image :data="data" receiver delivery textKey="Signature" />
            <small class="text-dark-darken slide-subtitle d-block">Assinatura de entrega</small>
          </slide>
          <slide data-index="1">
            <static-background-image :data="data" pickedup receiver delivery textKey="Signature" />
            <small class="text-dark-darken slide-subtitle d-block">Assinatura de coleta</small>
          </slide>
        </carousel>

        <span class="text-dark-darken font-weight-bold mb-2 mt-3">{{
          $t('orderEditModals.infoDetails.spotCarouselLocal') | capitalize
        }}</span>
        <carousel
          id="carousel-locals"
          autoplay
          loop
          :perPage="1"
          :adjustableHeight="true"
          :autoplayHoverPause="false"
          :centerMode="true"
          :mouseDrag="false"
          :paginationEnabled="false"
          :autoplayTimeout="3000"
          :paginationSize="0"
        >
          <slide data-index="0">
            <static-background-image :data="data" delivery textKey="PickupLocal" />
            <small class="text-dark-darken slide-subtitle d-block">Local de entrega</small>
          </slide>
          <slide data-index="1">
            <static-background-image :data="data" pickedup delivery textKey="DeliveryLocal" />
            <small class="text-dark-darken slide-subtitle d-block">Local de coleta</small>
          </slide>
        </carousel>
      </div>
    </div>
  </div>
</template>

<script>
import { Carousel, Slide } from 'vue-carousel';
import StaticBackgroundImage from './StaticBackgroundImage.vue';

export default {
  name: 'accordion-details',
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      slide: 0,
      sliding: null,
    };
  },
  components: {
    StaticBackgroundImage,
    Carousel,
    Slide,
  },
  computed: {
    getAddress() {
      const type = this.getOperationType === 'pickup' ? 'source' : 'destination';
      const address = this.data[type].address
        ? Object.values(this.data[type])
            .filter(e => !(e instanceof Object) && !!e)
            .join(' - ')
        : null;

      return address;
    },
    getOperationType() {
      return this.data.operation.type;
    },
    getSpotAddress() {
      const address = this.data.source.address
        ? Object.values(this.data.source)
            .filter(e => !(e instanceof Object) && !!e)
            .join(' - ')
        : null;

      return address;
    },
  },
};
</script>

<style lang="scss">
@keyframes pulse {
  0% {
    opacity: 0.3;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0.3;
  }
}

.VueCarousel-wrapper {
  position: relative;
  &::before {
    content: '';
    width: 100%;
    height: 100%;
    margin: 50% auto;
    background: url('../../../../assets/images/logo-uello-complete-red.svg') center center no-repeat;
    position: absolute;
    top: -90%;
    left: 0;
    z-index: 0;
    opacity: 0.3;
    animation: pulse 1200ms linear infinite forwards;
  }
}

.accordion-details {
  .slide-subtitle {
    transform: translateY(-12px);
  }

  span {
    font-size: 13px;
  }
}
</style>
